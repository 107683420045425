export const APP_NAME = 'Multitek'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'

export const LOCATION_TYPE_APARTMENT = 'apartment'
export const LOCATION_TYPE_VILLA = 'villa'
export const LOCATION_TYPE_SECURITY = 'security'
export const LOCATION_NAME_MAX_LENGTH = 20

export const USER_PASSWORD_MIN_LENGTH = 8
export const USER_PASSWORD_MAX_LENGTH = 16

export const VERIFICATION_CODE_LENGTH = 6
