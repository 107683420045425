import React from 'react'

const LogoSvg = (props) => {
   return (
      <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="508.17" height="112.05" viewBox="0 0 508.17 112.05" xmlSpace="preserve">
         <path fillRule="evenodd" clipRule="evenodd" fill="current" d="M367.94 71.1c0-22.54 17.08-40.83 38.16-40.83 12.3 0 23.23 6.2 30.21 15.88l-19.5 31.9c3.43-.47 11.4-1.51 11.4-1.51l5.55 23.77c-3.14 5.44-12.36 10.23-27.66 11.62-21 1.9-38.16-18.28-38.16-40.82m29.4-3.46a7.03 7.03 0 0 1 7.03-7.03c1.83 0 4.75 1.87 4.75 1.87l-8.54 11.07s-3.25-3.43-3.25-5.91m41.44-63.56h29.62v39.66s10.09-1.1 10.09-14.34h27.3s-.96 20.47-13.42 25.53c0 0 15.8 10.23 15.8 25.6v27.74H479.6V93.44c0-13.1-6.51-18.97-10.76-18.97v33.61h-30.11l.05-104zm-143.55 11c0-8.32 6.33-15.09 14.13-15.09 7.8 0 14.14 6.77 14.14 15.08 0 8.34-6.34 15.1-14.14 15.1-7.8 0-14.13-6.76-14.13-15.1m-.36 18.56h29.58v75.48h-29.58V33.64zm34.88-17.51h25.7v17.1H363v28.61h-7.45v19.5s-.67 4.19 1.81 4.19h5.69v22.58h-11.82s-21.5-.56-21.5-21.48v-70.5zm-74.84.36h25.67v17.13h7.59v28.6h-7.46v19.5s-.68 4.18 1.83 4.18h5.66v22.6h-11.81s-21.48-.6-21.48-21.5V16.5zm-45.62-11h30.11v76.03c0 4.42 5.26 4.32 5.26 4.32h4.57v23.48h-14.75s-25.32-2.74-25.32-26.23c0-23.5.13-77.6.13-77.6M135.53 30.3h27.35v45.5s.56 4.56 6.5 4.56c5.94 0 5.32-4.66 5.32-4.66V30.38h29.37V76.4s-2.86 34.2-34.2 34.2c-21.81 0-34.21-13.44-34.21-34.2 0-20.8-.13-46.1-.13-46.1M32.55 110V44.63s.22-9.25 7.24-9.18c0 0 8.61-2.13 8.61 9.18v65.36H80.86V44.63S81.4 35 89.04 35s7.64 6.96 7.64 9.63v65.36h32.45V31.26S130.18 1.8 94.3 1.8c-21.23 0-29.39 12.05-29.39 12.05s-8.1-12.14-30.3-12.14C12.37 1.71 0 14.99 0 27.44v82.55h32.55z"/>
      </svg>
   )
}

export default LogoSvg
