import Layout from 'components/layout'
import Theme from 'components/template/Theme'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import history from './history'
import './locales'
import store, { persistor } from './store'

function App() {
   return (
      <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter history={history}>
               <Theme>
                  <Layout />
                  <Helmet>
                     <script src="/newrelic.js" />
                  </Helmet>
               </Theme>
            </BrowserRouter>
         </PersistGate>
      </Provider>
   )
}

export default App
