import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { initialState, setUser } from 'store/auth/userSlice'
import useQuery from './useQuery'

function useAuth() {
   const dispatch = useDispatch()

   const navigate = useNavigate()

   const query = useQuery()

   const { t } = useTranslation()

   const { token, signedIn } = useSelector((state) => state.auth.session)

   const signIn = async (user, token) => {
      try {
         dispatch(onSignInSuccess(token))

         dispatch(
            setUser({
               avatar: '',
               userName: [user.name, user.surname].join(' '),
               authority: ['USER'],
               email: user.email
            })
         )

         const redirectUrl = query.get(REDIRECT_URL_KEY)

         navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
      }
      catch
         (errors) {
         return {
            status: 'failed',
            message: t('errors.generic')
         }
      }
   }

   const handleSignOut = () => {
      dispatch(onSignOutSuccess())
      dispatch(setUser(initialState))
      navigate(appConfig.unAuthenticatedEntryPath)
   }

   const signOut = async () => {
      // await apiSignOut()
      handleSignOut()
   }

   return {
      authenticated: token && signedIn,
      signIn,
      signOut
   }
}

export default useAuth
