export const ROUTES = {
   HOME: '/',
   SIGN_IN: '/sign-in',
   FORGOT_PASSWORD: '/forgot-password',
   DEVICE_MANAGER: '/device-manager',
   DEVICE_MANAGER_EDIT_LOCATION: '/device-manager/edit-location',

   DEVICE_MANAGER_VILLA: '/device-manager/villa',
   DEVICE_MANAGER_VILLA_MANAGE: '/device-manager/villa/manage',

   DEVICE_MANAGER_APARTMENT: '/device-manager/apartment',
   DEVICE_MANAGER_APARTMENT_MANAGE: '/device-manager/apartment/manage',

   DEVICE_MANAGER_SECURITY: '/device-manager/security',
   DEVICE_MANAGER_SECURITY_MANAGE: '/device-manager/security/manage',
}