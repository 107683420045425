import React from 'react'
import { HiOutlineMenu, HiOutlineMenuAlt2 } from 'react-icons/hi'
import { LogoSvg } from '../../assets/svg'

const NavToggle = ({ toggled, className }) => {
   return (
      <div className={className}>
         <div className="flex items-center gap-4">
            {toggled ? <HiOutlineMenu /> : <HiOutlineMenuAlt2 />}
            <LogoSvg className="fill-red-600 w-auto h-5" />
         </div>
      </div>
   )
}

export default NavToggle
