import { ROUTES } from '../constants/route.constant'

const API_URL = process.env.NODE_ENV === 'production' ? 'https://test.api.multitek.com.tr' : 'http://localhost:4000'

const appConfig = {
   apiUrl: API_URL,
   authenticatedEntryPath: ROUTES.HOME,
   unAuthenticatedEntryPath: ROUTES.SIGN_IN,
   tourPath: '/',
   locale: 'tr'
}

export default appConfig
